import { Box, Button, Typography } from "@material-ui/core";

import Companies from "../components/Home/Companies";
import Features from "../components/Home/Features";
import Head from "next/head";
import Header from "../components/Home/Header";
// Components
import PageWrapper from "../components/PageWrapper";
import Pixel from "../components/Pixel";
import Promotions from "../components/Home/Promotions";
// Query
import gql from "graphql-tag";
import { initializeApollo } from "../apollo";
// Authentication
import useAuth from "../authentication";
// Apollo
import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";
// Translation
import { useTranslation } from "next-translate";

// const HOME_QUERY = gql``;

const _Home = () => {
  const { t } = useTranslation();

  const { isAuthenticated } = useAuth();
  const router = useRouter();

  if (isAuthenticated) router.replace("/");
  
  // const {
  //   data: { cms },
  // } = useQuery(HOME_QUERY);

  return (
    <PageWrapper>
      <Pixel name="FACEBOOK_PIXEL" />
      <Head>
        <title> {t("home:title")}</title>
      </Head>
      <Header />
      <Promotions />
      {/* <Features /> */}
      {/* <Companies /> */}
    </PageWrapper>
  );
};

export default _Home;

export async function getStaticProps() {
  // const client = initializeApollo();
  // await client.query({
  //   query: HOME_QUERY,
  // });
  return {
    props: {
      // initialApolloState: client.cache.extract(),
    },
    // revalidate: 100,
  };
}
