// @ts-nocheck
import I18nProvider from 'next-translate/I18nProvider'
import React from 'react'
import C, * as _rest from '../pages_'
import ns0 from '../locales/ar/common.json'
import ns1 from '../locales/ar/navbar.json'
import ns2 from '../locales/ar/home.json'
import ns3 from '../locales/ar/privacy.json'
import ns4 from '../locales/ar/home.json'

const namespaces = { 'common': ns0, 'navbar': ns1, 'home': ns2, 'privacy': ns3, 'home': ns4 }

export default function Page(p){
  return (
    <I18nProvider 
      lang="ar" 
      namespaces={namespaces}  
      internals={{"defaultLanguage":"ar","isStaticMode":true}}
      false
    >
      <C {...p} />
    </I18nProvider>
  )
}

Page = Object.assign(Page, { ...C })

if(C && C.getInitialProps) {
  Page.getInitialProps = ctx => C.getInitialProps({ ...ctx, lang: 'ar'})
}


export const getStaticProps = ctx => _rest.getStaticProps({ ...ctx, lang: 'ar' })





